<template>
  <div>
    <AdaptedPhysicalActivitiesComp v-bind:mobile="mobile"/>
    <RedirectActivitiesComp v-bind:mobile="mobile"/>
    <FollowUs v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import AdaptedPhysicalActivitiesComp from '@/components/desktop/Activities/AdaptedPhysicalActivitiesComp'
import RedirectActivitiesComp from '@/components/desktop/Activities/RedirectActivitiesComp'
import FollowUs from '@/components/desktop/FollowUs'

  export default {
    name: 'AdaptedPhysicalActivities',
    props: ['mobile'],
    components: {
      AdaptedPhysicalActivitiesComp,
      RedirectActivitiesComp,
      FollowUs
    },
  }
</script>
